function App() {
  return (
    <div className='py-24 px-24 h-full w-screen min-h-screen h-full bg-slate-50 justify-start items-start flex flex-col gap-8'>
      <div className="flex flex-col gap-4 ">
        <p className='text-4xl  text-green-700 p-2'>
          Somnia Labs 
        </p>  
        <p className='text-2xl text-gray-900 p-2 '>
          We are Somnia Labs. We're developing on a new CPAP device that delivers a near-perfect breathing experience that is both effective and comfortable.
        </p>
      </div>  
      <div className=" flex flex-col gap-4 ">
        <p className='text-4xl  text-blue-700 p-2'>
            A New Approach 
          </p>  
          <p className='text-2xl text-gray-900 p-2 '>
            Our new CPAP device uses a combination of proprietary machine learning algorithms and hardware to build a deep understanding of a patients airway as they sleep. Using this understanding, our machine delivers just the perfect amount of pressure needed to maintain high quality breathing. 
            <br/>
            Using our machine, OSA patients sleep better than people without sleep apnea. We're in early trials now. Please get in touch to learn more. 
          </p>
      </div>
      <div className=" flex flex-col gap-4 ">
        <p className='text-4xl text-purple-900 p-2'>
            About Us 
          </p>  
          <div className="flex flex-col ">
          <p className='text-2xl text-gray-900 p-2 '>
            Somnia Labs was started by Reginald Long and Kanishk Vashisht - both lifelong OSA patients. We are backed by SciFi Capital, Caffeinated Capital, and South Park Commons. We are hiring for the following roles. Please get in touch if interested. 
          </p>
            <ul className="p-2 text-xl">
              <li>- Signals Processing Engineer</li>
              <li>- Industrial Designer / Design-For-Manufacture Engineer</li>
              <li>- Trials and Ops Coordinator</li>
            </ul>
          </div>
      </div>
      <a href="mailto:kanishk@accessos.com"><p className="text-2xl p-2 text-blue-800 underline underline-offset-2">Contact Us</p></a>
    </div>
  );
}

export default App;
